<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Change Password</v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <v-col lg="6" sm="12" cols="12">
                  <v-text-field
                    v-model="passwordObj.oldpassword"
                    label="Old Password"
                    outlined
                    dense
                    :rules="passwordRules"
                  ></v-text-field>
                  <v-text-field
                    v-model="passwordObj.password"
                    label="New Password"
                    outlined
                    dense
                    :rules="passwordRules"
                  ></v-text-field>
                  <v-text-field
                    v-model="passwordObj.newpassword"
                    label="Confirm Password"
                    outlined
                    dense
                    :rules="passwordRules"
                  ></v-text-field>
                  <v-btn @click="submit" color="success" rounded>
                    Change Password
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      valid: false,
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Password must have 8+ characters",
        (v) =>
          /(?=.*[A-Z])/.test(v) || "Password must have one uppercase character",
        (v) => /(?=.*\d)/.test(v) || "Password must have one number",
        (v) =>
          /([!@$%])/.test(v) ||
          "Password must have one special character [!@#$%]",
      ],
      passwordObj: {
        oldpassword: "",
        password: "",
        newpassword: "",
      },
    };
  },
  methods: {
    ...mapActions(["changePassword"]),
    submit() {
      if (this.$refs.form.validate()) {
        this.changePassword(this.passwordObj).then((res) => {
          if (res.data === 204) {
            this.$swal(
              "something wrong",
              "Please check if your old password is correct, or new passwords match.",
              "error"
            );
          } else if (res.data === 200) {
            this.$refs.form.reset();
            this.$swal("Password changed!", "", "success");
          }
        });
      }
    },
  },
};
</script>
